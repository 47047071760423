<template>
  <div>
    <div class="detail_icon">
      <i class="el-icon-arrow-left" @click="backToList"
        ><span>返回列表</span></i
      >
    </div>

    <div class="detail_content">
      <div class="detail_content_phone">
        <div style="margin: 0">
          手机号：{{ detailData.phoneArea }} {{ detailData.phone }}
        </div>
        <div class="detail_image_preview_font">
          {{ detailData.driverSubmitDate }} 发布<span
            >(点击下方图片可查看大图)</span
          ><img src="@/assets/down.png" alt="" />
        </div>
      </div>
      <div class="detail_image_preview">
        <div class="img_flex">
          <el-image
            v-for="(item, index) in imgArr"
            :key="index"
            :src="item"
            :preview-src-list="getPreviewImgList(index, imgArr)"
          >
          </el-image>
        </div>
      </div>
      <div class="detail_content_info">
        <div style="margin-right: 100px">
          <div>
            真实姓名：<span>{{ detailData.realName }}</span>
          </div>
          <div>
            身份证号：<span>{{ detailData.realId }}</span>
          </div>
          <div>
            驾驶证注册时间：<span>{{ detailData.licenseGetDate }}</span>
          </div>
          <div>
            驾驶证失效时间：<span>{{ detailData.licenseExpireDate }}</span>
          </div>
          <div>
            行驶证注册时间：<span>{{ detailData.drivingRegDate }}</span>
          </div>
        </div>
        <div>
          <div>
            车辆所有人：<span>{{ detailData.carOwner }}</span>
          </div>
          <div>
            车牌号码：<span>{{ detailData.carNumber }}</span>
          </div>
          <div>
            车辆型号：<span>{{ detailData.car }}</span>
          </div>
          <div>
            车辆类型：<span>{{ detailData.carType }}</span>
          </div>
          <div>
            使用性质：<span>{{ detailData.carNature }}</span>
          </div>
        </div>
      </div>
      <div class="detail_content_btn">
        <el-button type="info" round @click="cancellation">注 销</el-button>
      </div>
      <div class="detail_content_no_reason" v-if="detailData.status != 2">
        拒绝原因：{{ detailData.statusMsg }}
      </div>
    </div>
  </div>
</template>
<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
export default {
  data() {
    return {
      detailData: {},
      imgArr: [],
    };
  },

  mounted() {
    const opt = {
      url: reqUrl.getDriverDetail,
      method: "POST",
      params: localStorage.getItem("ownerListID"),
      resFunc: (res) => {
        console.log(res);
        this.detailData = res.data.data;
        this.imgArr.push(
          res.data.data.driverLicencePictureMain,
          res.data.data.drivingLicensePictureMain,
          res.data.data.carFrontPicture,
          res.data.data.carBehindPicture
        );
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },
  methods: {
    backToList() {
      this.$router.back(); //返回表格页
    },

    getPreviewImgList(index, imgArr) {
      let arr = [];
      let i = 0;
      for (i; i < imgArr.length; i++) {
        arr.push(imgArr[i + index]);
        if (i + index >= imgArr.length - 1) {
          index = 0 - (i + 1);
        }
      }
      return arr;
    },

    cancellation() {
      this.$confirm("您确定要注销吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const opt = {
            url: reqUrl.cancelDetailDriver,
            method: "POST",
            params: JSON.stringify({
              nonce: "",
              examineId: localStorage.getItem("ownerListID"),
              sign: "",
              token: "",
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "注销成功!",
                });
                this.$router.push("/AuditList");
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消注销",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}
.detail_icon {
  height: 40px;

  span {
    font-size: 14px;
  }
}

.detail_content {
  margin-left: 15px;
  color: #333;
  font-size: 14px;

  .detail_content_phone {
    display: flex;
    align-items: center;

    .detail_image_preview_font {
      display: flex;
      align-items: center;
      align-items: center;
      margin-left: 50px;
      color: #475669;

      img {
        width: 18px;
        height: 25px;
        margin-left: 10px;
      }
    }
  }

  .detail_image_preview {
    margin-top: 8px;
    .img_flex {
      display: flex;
      justify-content: space-between;
      margin: 0;

      .el-image {
        width: 380px;
        height: 300px;
        margin-right: 5px;
      }
    }

    span {
      font-size: 12px;
    }
  }

  .detail_content_info {
    width: 100%;
    margin: 8px auto;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    line-height: 30px;
  }

  .detail_content_btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .el-button {
      width: 200px;
    }
  }

  .detail_content_no_reason {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: 500;
  }
}
</style>
